#welcome {
	z-index: 100;
	text-shadow: 1px 1px 5px #000;
}

.slides {
	z-index: 1;
	position: absolute;
	top: 0; left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	background-size: cover;
	background-position: center;
}
