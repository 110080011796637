/*	===============	Utilities ===============	*/

	.transitions,
	.transitions * {
		-webkit-transition: all .25s ease-out;
		transition: all .25s ease-out;
	}

	h1 {
		font-family: 'Catamaran', 'Lato', sans-serif;
	}
	p, strong {
		font-weight: $font-normal;
	}
	.text-white {
		color: $color-light !important;
	}
	.text-muted {
		color: $color-lightgrey !important;
	}


/*	===============	InFolder ===============	*/

	.grey {
		background-color: $color-background !important;
	}
	.navbar.vshadow {
		-webkit-box-shadow: 0 0 20px 10px rgba(30, 30, 30, .8);
		        box-shadow: 0 0 20px 10px rgba(30, 30, 30, .8);
	}
	.footer.vshadow {
		-webkit-box-shadow: 0 0 5px 3px rgba(30, 30, 30, .8);
		        box-shadow: 0 0 5px 3px rgba(30, 30, 30, .8);
	}
	.transparent {
		background-color: rgba(0,0,0,0.35) !important; 
		/*box-shadow: none !important;*/
	}
	.contour {
		border: 1px solid $color-lightgrey;
		padding: 1.5rem;
	}



/*	===============	LISTS	===============	*/

	.list {
		display: inline-block;
		padding: 0;
		& > li {
			padding: 1em;
			list-style-type: none;
			border-bottom: 1px solid $color-lightgrey;
			&:last-child { border-bottom: none; }
			& > a {
				color: $color-light !important;
				display: block;
			}
		}
	}

